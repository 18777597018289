import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';
import image7 from '../assets/image7.webp';
import image8 from '../assets/image8.webp';
import image9 from '../assets/image9.webp';

const products = [
  { id: 1, name: 'Custom Back To School Pencil Case', price: '15.99', image: image1, badge: 'Custom', description: 'Organize your pens and pencils in style with this custom-designed pencil case. Perfect for students who want to stay organized and look trendy.' },
  { id: 2, name: 'Custom Pastel Pencil Case', price: '12.50', image: image2, badge: 'Custom', description: 'This pastel-colored pencil case is both stylish and practical. Ideal for those who appreciate quality and elegance in their stationery.' },
  { id: 3, name: 'Custom Ruler', price: '14.75', image: image3, badge: 'Custom', description: 'A durable ruler with clear markings for precise measurements. Essential for students and professionals who require accuracy in their work.' },
  { id: 4, name: 'Wire Bookcase', price: '19.99', image: image4, badge: 'New', description: 'Versatile wire bookcase for organizing books and decorative items. Its minimalist design fits well in any modern living or working space.' },
  { id: 5, name: 'Apple Earrings', price: '17.25', image: image5, badge: 'New', description: 'Adorable apple-shaped earrings made from high-quality materials. Perfect for adding a touch of whimsy to any outfit or occasion.' },
  { id: 6, name: 'Teacher Name Plate', price: '18.99', image: image6, badge: 'New', description: 'Personalized name plate for teachers, showcasing professionalism and dedication. A thoughtful addition to any educator’s desk.' },
  { id: 7, name: '"Make mistakes" Vinyl Sticker', price: '10.99', image: image7, badge: 'First day of school', description: 'Vinyl sticker with an inspiring message that encourages creativity and learning from mistakes. Great for decorating laptops, notebooks, and more.' },
  { id: 8, name: '"First day of school" Pennant', price: '13.95', image: image8, badge: 'First day of school', description: 'Celebrate the first day of school with this decorative pennant. Adds a festive touch to classrooms or homes welcoming the new academic year.' },
  { id: 9, name: 'School Themed Garland', price: '16.50', image: image9, badge: 'First day of school', description: 'Elegant garland featuring school-themed motifs. Perfect for decorating classrooms, parties, or study areas with style and charm.' },
];

export default products;
