import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Assuming you have a CSS module for styling

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>OUR COMMITMENT TO YOUR PRIVACY</h1>
      <p>At {siteName}, we deeply respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you shop for gifts for teachers on our website.</p>

      <h2>1. INFORMATION WE COLLECT</h2>
      <p><strong>Personal Information:</strong> When you make a purchase, we collect necessary details like your name, email, shipping address, and payment information to fulfill your order.</p>
      <p><strong>Website Usage Information:</strong> We use cookies and similar technologies to enhance your browsing experience and to understand how you interact with our site.</p>

      <h2>2. HOW WE USE YOUR INFORMATION</h2>
      <p><strong>Order Processing:</strong> Your personal data is used to process and fulfill your orders, including communicating with you about your purchase.</p>
      <p><strong>Customer Support:</strong> We use your contact information to provide customer support and to respond to your inquiries and concerns.</p>
      <p><strong>Personalization:</strong> With your consent, we may personalize your shopping experience based on your preferences and past purchases.</p>
      <p><strong>Improving Our Services:</strong> We analyze data to enhance our website functionality, product offerings, and overall shopping experience for teachers.</p>

      <h2>3. INFORMATION SHARING</h2>
      <p><strong>Third-Party Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, processing transactions, and delivering orders.</p>
      <p><strong>Legal Compliance:</strong> We may disclose your information when required by law or to protect our rights, property, or safety, including in response to legal requests.</p>

      <h2>4. DATA SECURITY</h2>
      <p>We implement robust security measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.</p>

      <h2>5. YOUR PRIVACY CHOICES</h2>
      <p><strong>Communication Preferences:</strong> You can manage your communication preferences, including opting out of marketing emails, by contacting us or adjusting your account settings.</p>
      <p><strong>Cookies:</strong> You can control cookies through your browser settings or other tools. Please note, disabling cookies may impact your browsing experience on our site.</p>

      <h2>6. CHILDREN'S PRIVACY</h2>
      <p>We do not knowingly collect personal information from children under the age of 13. If we discover that we have inadvertently collected information from a child, we will promptly delete it.</p>

      <h2>7. UPDATES TO THIS PRIVACY POLICY</h2>
      <p>We may update this Privacy Policy periodically to reflect changes in our practices and legal requirements. We encourage you to review this policy regularly to stay informed about how we are protecting your information.</p>

      <p>If you have any questions about our Privacy Policy or how we handle your data, please contact us at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a>. Your privacy matters to us, and we are committed to addressing your concerns promptly.</p>
    </div>
  );
};

export default Privacy;
