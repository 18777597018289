// src/pages/AboutUs.js
import React from 'react';
import styles from './AboutUs.module.css';
import heroBackground from '../assets/aboutus.webp'; // Import the image

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <img 
        src={heroBackground} 
        alt="About Us" 
        className={styles.aboutUsImage}
      />
      <div className={styles.aboutUsText}>
        <h2>Exciting News from BuffaloFrenzy.com!</h2>
        <p>
        We're thrilled to announce our upcoming Teacher Appreciation Sale! 🎁<br/> Starting next week, enjoy fantastic discounts on a wide range of unique and thoughtful gifts for teachers. Whether you're looking for personalized items, practical supplies, or something special to show your gratitude, we've got you covered.
        </p>
       
      </div>
    </div>
  );
};

export default AboutUs;
