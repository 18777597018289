import React from 'react';
import ProductCard from '../components/ProductCard';
import products from '../data/productsData';
import styles from './Home.module.css';
import Hero from '../components/Hero';

const Home = () => {
  // Filter products based on different criteria
  const newProducts = products.filter(product => product.badge === 'Custom');
  const saleProducts = products.filter(product => product.badge === 'New');
  const exclusiveProducts = products.filter(product => product.badge === 'First day of school');

  // Function to chunk products into groups of 3
  const chunkProducts = (arr, size) => {
    return arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
  };

  // Chunk each category into groups of 3
  const chunkedNewProducts = chunkProducts(newProducts, 3);
  const chunkedSaleProducts = chunkProducts(saleProducts, 3);
  const chunkedExclusiveProducts = chunkProducts(exclusiveProducts, 3);

  return (
    <>
      <Hero />
      <div id="products-section" className={styles.container}>
        <h1 id="custom-products-section" className={styles.heading}>Customizable Gifts</h1>
        {chunkedNewProducts.map((group, index) => (
          <div key={index} className={styles.productGroup}>
            <div className={styles.grid}>
              {group.map(product => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Render Sale Products */}
      <div className={styles.container}>
        <h1 id="teacher-products-section" className={styles.heading}>New Arrivals</h1>
        {chunkedSaleProducts.map((group, index) => (
          <div key={index} className={styles.productGroup}>
            <div className={styles.grid}>
              {group.map(product => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Render Exclusive Products */}
      <div className={styles.container}>
        <h1 id="student-products-section" className={styles.heading}>Fist Day Of School Special</h1>
        {chunkedExclusiveProducts.map((group, index) => (
          <div key={index} className={styles.productGroup}>
            <div className={styles.grid}>
              {group.map(product => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
